import React from "react"
import { graphql } from "gatsby"

import { Header } from "../components/Header"
import { Layout } from "../components/layout"
import { Login } from "../components/auth/Login"

const LoginPage = (props) => {
  const page = props.data.allDataJson.edges[0].node.pages.login
  const { meta, ...data } = page
  return (
    <Layout meta={meta} loadUikit={true}>
      <Header />
      <Login data={data} />
    </Layout>
  )
}

export default LoginPage

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          pages {
            login {
              meta {
                url
                title
                description
                imageRootUrl
                image
              }
              bg {
                childImageSharp {
                  fluid(
                    maxWidth: 2880
                    quality: 80
                    srcSetBreakpoints: [
                      360
                      640
                      768
                      1080
                      1280
                      1366
                      1440
                      1920
                      2880
                    ]
                  ) {
                    src
                    sizes
                    srcSet
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
