import styled from "styled-components"

import lockIcon from "../../../images/lock.svg"

const padding = 12

const StyledTextBox = styled.input.attrs({
  type: "password",
  placeholder: "Password",
  name: "password",
})`
  background: url("${lockIcon}") left ${padding}px center no-repeat #ffffff;
  border: none;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15), 0 1px 0 0 rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  width: 100%;
  height: 46px;
  padding: ${padding}px;
  padding-left: ${padding * 2 + 24}px;
  margin-bottom: 24px;
`

export const Password = StyledTextBox
