import React, { Component } from "react"
import styled from "styled-components"

import { FluidBackgroundImage } from "../../FluidBackgroundImage"
import { height as headerHeight } from "../../Header"
import { Email } from "./Email"
import { Password } from "./Password"
import { RememberMe } from "./RememberMe"

const baseSize = 16

const BackgroundStyled = styled(FluidBackgroundImage)`
  height: calc(100vh - ${headerHeight}px);
  width: 100%;
  position: relative;
  margin-top: 0;
  top: ${headerHeight}px;
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
`

const LoginBox = styled.form`
  width: 350px;
  max-width: 100vw;
  color: #8898aa;
  background-color: #f4f5f7;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 32px;
`

const LoginLabel = styled.div`
  text-align: center;
  font-size: ${14 / baseSize}rem;
  margin-bottom: 32px;
`

const ErrorMsg = styled.div`
  text-align: center;
  color: red;
  opacity: 0.6;
  margin-bottom: 8px;
`

const StyledButtonWrapper = styled.div`
  text-align: center;
`

const StyledButton = styled.button`
  border: none;
  background: #5e72e4;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.08),
    0 4px 6px 0 rgba(50, 50, 93, 0.11);
  border-radius: 4px;
  color: white;
  width: 100px;
  height: 43px;
  cursor: pointer;
  :active {
    opacity: 0.8;
  }
  :disabled {
    cursor: default;
    opacity: 0.3;
  }
`

export class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      submitting: false,
      email: "",
      password: "",
      errorMsg: "",
    }
  }

  submit = (e) => {
    e.preventDefault()
    this.setState({ ...this.state, submitting: true })
    if (fetch && encodeURIComponent) {
      const email = encodeURIComponent(this.state.email)
      const password = encodeURIComponent(this.state.password)
      fetch(`/login.json?email=${email}&password=${password}`, {
        method: "GET",
        headers: {
          Content_type: "application/x-www-form-urlencoded",
        },
      })
        .then((resp) => resp.json())
        .then((body) => {
          if (body.success) {
            this.setState({ submitting: false, errorMsg: "" })
          } else {
            this.setState({
              submitting: false,
              errorMsg: "Oops, wrong email or password.",
            })
          }
        })
        .catch((e) => {
          console.log(e)
          this.setState({ submitting: false, errorMsg: "Unable to login" })
        })
    }
  }

  render() {
    return (
      <BackgroundStyled fluid={this.props.data.bg.childImageSharp.fluid}>
        <LoginBox onSubmit={this.submit}>
          <LoginLabel>Sign in to continue</LoginLabel>
          <Email
            onChange={(e) =>
              this.setState({ email: e.target.value, errorMsg: "" })
            }
          />
          <Password
            onChange={(e) =>
              this.setState({ password: e.target.value, errorMsg: "" })
            }
          />
          <RememberMe />
          <ErrorMsg style={{ visibility: !!this.state.errorMsg }}>
            {this.state.errorMsg}
          </ErrorMsg>
          <StyledButtonWrapper>
            <StyledButton disabled={this.state.submitting}>
              SIGN IN
            </StyledButton>
          </StyledButtonWrapper>
        </LoginBox>
      </BackgroundStyled>
    )
  }
}
